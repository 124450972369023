@import 'variables';

.oups {
    display: flex;

    h1 {
        font-size: 6em;
        color: $secondary;
        margin: 0.3em;
    }
    img {
        border-radius: 5px;
        width: 50em;
        box-shadow: 0 5px 10px 0 rgba($color: black, $alpha: .5);

        @media screen and (max-width: 50em) {
            width: 90%;
        }
    }
}