@import 'variables';

.search-bar {
    display: flex;
    justify-content: start;
    height: 2em;
    font-size: 0.5em;
    width: 50%;
    border-radius: 3px;
    background-color: white;

    @media screen and (max-width: 768px) {
        width: 12em;
    }

    .search-logo {
        width: 1.7em;
        padding-left: 0.2em;
        opacity: 0.3;
    }

    .search-input {
        border: none;
        border-radius: 3px;
        width: 93%;
        background-color: white;

        input {
            width: 100%;
            height: 100%;
            border: none;
            background-color: transparent;
            font-size: 1em;
            padding-left: 0.5em;
            outline: none;
        }

        @media screen and (max-width: 768px) {
            width: 75%;
        }
    }

    a {
        padding: 0.5em 1em;
        border: none;
        background-color: $secondary;
        color: white;
        text-decoration: none;
        cursor: pointer;

        &:hover {
            background-color: rgba($secondary, .5);
        }
    }
}

.search-bar-home {
    display: flex;
    flex-direction: row;
    justify-content: start;
    width: 40em;
    gap: 1em;
    margin-top: 2em;
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);

    @media screen and (max-width: 768px) {
        width: 95%;
    }

    .search-logo {
        width: 1.7em;
        margin-left: 0.8em;
        opacity: 0.3;
    }

    .search-input {
        border: none;
        border-radius: 3px;
        width: 93%;

        input {
            width: 100%;
            height: 3em;
            border: none;
            background-color: transparent;
            font-size: 1em;
            padding-left: 0.5em;
            outline: none;
        }

        @media screen and (max-width: 768px) {
            width: 75%;
        }
    }

    button {
        padding: 0.5em 1em;
        border-radius: 2px;
        border: none;
        background-color: $secondary;
        color: white;
        text-decoration: none;
        font-size: 1.3em;
        cursor: pointer;

        &:hover {
            background-color: darken($secondary, 10%);
        }
    }
}
