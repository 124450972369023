@import 'variables';

button {
    padding: 0.5em;
    border: none;
    border-radius: 5px;
    background-color: $secondary;
    color: white;
    cursor: pointer;
    font-size: 1em;
    width: 5em;
    transition: background-color 0.3s;
    box-shadow: 0 3px 3px 0 rgba($color: black, $alpha: .16);

    &:hover {
        background-color: darken($secondary, 10%);
    }
}

.movies-filters {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 20px 0;
    padding: 0;
    list-style: none;
    gap: 0.3em;
}
