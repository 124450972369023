@import 'variables';

select {
   -webkit-appearance: none;
   -moz-appearance: none;
   -ms-appearance: none;
   appearance: none;
   outline: 0;
   box-shadow: none;
   border: 0 !important;
   background: #eeeeee;
   background-image: none;
   flex: 1;
   padding: 0 .5em;
   color: $primary;
   cursor: pointer;
   font-size: 1em;
   font-family: 'Open Sans', sans-serif;
}

select::-ms-expand {
   display: none;
}

.select {
   position: relative;
   display: flex;
   width: 10em;
   height: 2em;
   line-height: 3;
   background: #d3d3d3;
   overflow: hidden;
   border-radius: .25em;
   box-shadow: 0 3px 3px 0 rgba($color: black, $alpha: .16);

   @media screen and (max-width: 768px) {
      width: 90%;

   }
}
