@import 'variables';

header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-size: 2em;
    color: $primary;
    height: 7vh;
    z-index: 1000;

    .logo-searchbar {
        background-color: $primary;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        box-shadow: 0px 0px 5px 2px rgba($color: #000000, $alpha: 0.3);

        a {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.App-logo {
    height: 7vh;
    pointer-events:stroke;
}
