@import 'variables';

.filters {
  display: flex;
  flex-direction: row;
  gap: 0.2em;
  width: 70%;
  justify-content: center;
  padding: 0;
  list-style: none;
  // background-color: rgb(202, 202, 202);
  padding: 1em;
  border-radius: 5px;
  // box-shadow: inset 0 3px 3px 0 rgba(0, 0, 0, 0.16);

  @media screen and (max-width: 768px) {
    width: 90%;
    flex-direction: column;
  }

  .filter {
    display: flex;
    flex-direction: column;
    gap: 0.2em;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;

    @media screen and (max-width: 768px) {
      flex-direction: row;
      justify-content: start;
    }

    h3 {
      margin: 0;
      padding: 0;
      color: $primary;

      @media screen and (max-width: 768px) {
        width: 6em;
        line-height: 1.7em;
      }
    }
  }
}
