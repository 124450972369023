@import 'variables';

footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: $primary;
    color: rgba(255, 255, 255, 0.568);
    text-align: center;
    padding: 10px;
    width: 100%;
    height: 5vh;
    margin-top: auto;
}
