@import 'variables';

nav {
  width: 2em;
  position: relative;
}

#menuToggle {
  display: flex;
  flex-direction: column;
  top: 33px;
  right: 20px;
  -webkit-user-select: none;
  user-select: none;

  ul {
    padding: 0;
    list-style-type: none;
  }
}

#menuToggle input {
  display: flex;
  width: 40px;
  height: 30px;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
}

#menuToggle span {
  display: flex;
  width: 29px;
  height: 2px;
  margin-bottom: 5px;
  position: relative;
  background: #ffffff;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 5px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
    opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

#menuToggle input:checked~span {
  opacity: 1;
  transform: rotate(45deg) translate(-3px, -1px);
  background: #ffffff;
}

#menuToggle input:checked~span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked~span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

#menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  overflow-y: auto;
  background-color: $secondary;
  -webkit-font-smoothing: antialiased;
  transform-origin: 100% 0%;
  transform: translate(100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
}

#menu li {
  padding: 10px 10px;
  transition-delay: 2s;

  a {
    text-decoration: none;
    color: #e2e2e2;
    opacity: 1;
    font-size: 1em;
    font-weight: 400;
    transition: 200ms;
  }

  a:hover {
    opacity: 0.5;
  }
}

#menuToggle input:checked~ul {
  transform: none;
}
