* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body {
    height: 100%;
    margin: 0;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: rgb(204, 204, 204);
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
}

main {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    justify-content: flex-start;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

h1 {
    width: 100%;
    text-align: center;
}

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    width: 80em;
    background-color: rgb(225, 225, 225);
    height: 100%;
    margin: auto;
    padding-top: 2em;
    @media screen and (max-width: 80em){
        width: 100%;
    }
}