* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  margin: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(204, 204, 204);
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

main {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  justify-content: flex-start;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

h1 {
  width: 100%;
  text-align: center;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 80em;
  background-color: rgb(225, 225, 225);
  height: 100%;
  margin: auto;
  padding-top: 2em;
}
@media screen and (max-width: 80em) {
  .main {
    width: 100%;
  }
}

header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: 2em;
  color: #282c34;
  height: 7vh;
  z-index: 1000;
}
header .logo-searchbar {
  background-color: #282c34;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.3);
}
header .logo-searchbar a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.App-logo {
  height: 7vh;
  pointer-events: stroke;
}

footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #282c34;
  color: rgba(255, 255, 255, 0.568);
  text-align: center;
  padding: 10px;
  width: 100%;
  height: 5vh;
  margin-top: auto;
}

.search-result {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.search-result > ul:first-of-type {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1em;
  list-style: none;
  padding: 0;
  margin: 0;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding-bottom: 1.5em;
}
.pagination li {
  display: inline-block;
}
.pagination li a {
  display: flex;
  padding: 5px;
  border: 1px solid #ccc;
  color: #333;
  text-decoration: none;
}
.pagination li a:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}
.pagination li.active a {
  background-color: #3b7e91;
  color: #fff;
}

.movie-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 30em;
  gap: 1em;
  padding: 1em;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 768px) {
  .movie-card {
    width: 90%;
  }
}
.movie-card img {
  width: 25em;
  border-radius: 5px;
}
@media screen and (max-width: 768px) {
  .movie-card img {
    width: 95%;
  }
}
.movie-card h3 {
  font-size: 1.2em;
  text-align: center;
  color: #282c34;
}
.movie-card h5 {
  font-size: 0.9em;
  text-align: center;
  color: rgb(80, 80, 80);
  font-weight: normal;
}
.movie-card .movie-desc {
  font-size: 1em;
  text-align: start;
  width: 89%;
}
.movie-card a {
  padding: 0.5em 1em;
  border-radius: 2px;
  background-color: #3b7e91;
  color: white;
  text-decoration: none;
  cursor: pointer;
}
.movie-card a:hover {
  background-color: rgba(59, 126, 145, 0.5);
}

.search-bar {
  display: flex;
  justify-content: start;
  height: 2em;
  font-size: 0.5em;
  width: 50%;
  border-radius: 3px;
  background-color: white;
}
@media screen and (max-width: 768px) {
  .search-bar {
    width: 12em;
  }
}
.search-bar .search-logo {
  width: 1.7em;
  padding-left: 0.2em;
  opacity: 0.3;
}
.search-bar .search-input {
  border: none;
  border-radius: 3px;
  width: 93%;
  background-color: white;
}
.search-bar .search-input input {
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  font-size: 1em;
  padding-left: 0.5em;
  outline: none;
}
@media screen and (max-width: 768px) {
  .search-bar .search-input {
    width: 75%;
  }
}
.search-bar a {
  padding: 0.5em 1em;
  border: none;
  background-color: #3b7e91;
  color: white;
  text-decoration: none;
  cursor: pointer;
}
.search-bar a:hover {
  background-color: rgba(59, 126, 145, 0.5);
}

.search-bar-home {
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: 40em;
  gap: 1em;
  margin-top: 2em;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 768px) {
  .search-bar-home {
    width: 95%;
  }
}
.search-bar-home .search-logo {
  width: 1.7em;
  margin-left: 0.8em;
  opacity: 0.3;
}
.search-bar-home .search-input {
  border: none;
  border-radius: 3px;
  width: 93%;
}
.search-bar-home .search-input input {
  width: 100%;
  height: 3em;
  border: none;
  background-color: transparent;
  font-size: 1em;
  padding-left: 0.5em;
  outline: none;
}
@media screen and (max-width: 768px) {
  .search-bar-home .search-input {
    width: 75%;
  }
}
.search-bar-home button {
  padding: 0.5em 1em;
  border-radius: 2px;
  border: none;
  background-color: #3b7e91;
  color: white;
  text-decoration: none;
  font-size: 1.3em;
  cursor: pointer;
}
.search-bar-home button:hover {
  background-color: #2c5f6d;
}

.filters {
  display: flex;
  flex-direction: row;
  gap: 0.2em;
  width: 70%;
  justify-content: center;
  padding: 0;
  list-style: none;
  padding: 1em;
  border-radius: 5px;
}
@media screen and (max-width: 768px) {
  .filters {
    width: 90%;
    flex-direction: column;
  }
}
.filters .filter {
  display: flex;
  flex-direction: column;
  gap: 0.2em;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;
}
@media screen and (max-width: 768px) {
  .filters .filter {
    flex-direction: row;
    justify-content: start;
  }
}
.filters .filter h3 {
  margin: 0;
  padding: 0;
  color: #282c34;
}
@media screen and (max-width: 768px) {
  .filters .filter h3 {
    width: 6em;
    line-height: 1.7em;
  }
}

nav {
  width: 2em;
  position: relative;
}

#menuToggle {
  display: flex;
  flex-direction: column;
  top: 33px;
  right: 20px;
  -webkit-user-select: none;
  user-select: none;
}
#menuToggle ul {
  padding: 0;
  list-style-type: none;
}

#menuToggle input {
  display: flex;
  width: 40px;
  height: 30px;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
}

#menuToggle span {
  display: flex;
  width: 29px;
  height: 2px;
  margin-bottom: 5px;
  position: relative;
  background: #ffffff;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 5px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

#menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-3px, -1px);
  background: #ffffff;
}

#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

#menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  overflow-y: auto;
  background-color: #3b7e91;
  -webkit-font-smoothing: antialiased;
  transform-origin: 100% 0%;
  transform: translate(100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

#menu li {
  padding: 10px 10px;
  transition-delay: 2s;
}
#menu li a {
  text-decoration: none;
  color: #e2e2e2;
  opacity: 1;
  font-size: 1em;
  font-weight: 400;
  transition: 200ms;
}
#menu li a:hover {
  opacity: 0.5;
}

#menuToggle input:checked ~ ul {
  transform: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: #eeeeee;
  background-image: none;
  flex: 1;
  padding: 0 0.5em;
  color: #282c34;
  cursor: pointer;
  font-size: 1em;
  font-family: "Open Sans", sans-serif;
}

select::-ms-expand {
  display: none;
}

.select {
  position: relative;
  display: flex;
  width: 10em;
  height: 2em;
  line-height: 3;
  background: #d3d3d3;
  overflow: hidden;
  border-radius: 0.25em;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.16);
}
@media screen and (max-width: 768px) {
  .select {
    width: 90%;
  }
}

button {
  padding: 0.5em;
  border: none;
  border-radius: 5px;
  background-color: #3b7e91;
  color: white;
  cursor: pointer;
  font-size: 1em;
  width: 5em;
  transition: background-color 0.3s;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.16);
}
button:hover {
  background-color: #2c5f6d;
}

.movies-filters {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 20px 0;
  padding: 0;
  list-style: none;
  gap: 0.3em;
}

.error {
  color: red;
  text-align: center;
  position: absolute;
  top: 6em;
  left: 0;
  right: 0;
}

/* HTML: <div class="loader"></div> */
/* HTML: <div class="loader"></div> */
.loader {
  margin: auto;
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #3b7e91;
  --_m:
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}
.movie-detail {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  margin: 0;
  width: 90%;
  gap: 2em;
  padding-bottom: 1.5em;
}
@media screen and (max-width: 768px) {
  .movie-detail {
    width: 95%;
    flex-direction: column;
  }
}
.movie-detail .detail-pic {
  width: 30em;
  border-radius: 5px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.4);
}
@media screen and (max-width: 768px) {
  .movie-detail .detail-pic {
    width: 100%;
  }
}
.movie-detail .detail-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  gap: 1em;
  text-align: center;
  position: relative;
}
.movie-detail .detail-content .detail-top {
  width: 100%;
}
.movie-detail .detail-content .detail-top p {
  text-align: justify;
}
.movie-detail .detail-content .detail-title {
  font-size: 2em;
  font-weight: bold;
  text-align: center;
  padding-bottom: 1em;
}
.movie-detail .detail-content .detail-infos {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
  margin-top: 0.6em;
}
.movie-detail .detail-content .detail-infos a {
  text-decoration: none;
  color: #3b7e91;
}
.movie-detail .detail-content .detail-infos a:hover {
  color: rgba(59, 126, 145, 0.7);
}
.movie-detail .detail-content .original-title {
  font-size: 1.2em;
}
.movie-detail .detail-content .overview {
  font-size: 1.2em;
  text-align: justify;
}
.movie-detail .detail-content h3 {
  text-align: start;
}
.movie-detail .detail-content .detail-bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1em;
  width: 100%;
  margin-top: 2em;
}
@media screen and (max-width: 768px) {
  .movie-detail .detail-content .detail-bottom {
    flex-direction: column;
  }
}
.movie-detail .detail-content .detail-bottom .back {
  width: 7em;
}
.movie-detail .detail-content .genres {
  font-size: 1.2em;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 0.5em;
}
@media screen and (max-width: 768px) {
  .movie-detail .detail-content .genres {
    justify-content: center;
  }
}
.movie-detail .detail-content .genres li {
  display: inline;
  color: #3b7e91;
  list-style: none;
}
.movie-detail .detail-content .genres a {
  text-decoration: none;
  background-color: rgb(240, 240, 240);
  padding: 0.5em;
  height: 2em;
  line-height: 1em;
  border-radius: 3px;
}
.movie-detail .detail-content .genres a:hover {
  background-color: rgba(59, 126, 145, 0.1);
}

.oups {
  display: flex;
}
.oups h1 {
  font-size: 6em;
  color: #3b7e91;
  margin: 0.3em;
}
.oups img {
  border-radius: 5px;
  width: 50em;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
}
@media screen and (max-width: 50em) {
  .oups img {
    width: 90%;
  }
}

