@import 'variables';



/* HTML: <div class="loader"></div> */
/* HTML: <div class="loader"></div> */
.loader {
    margin: auto;
    width: 50px;
    padding: 8px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: $secondary;
    --_m:
      conic-gradient(#0000 10%,#000),
      linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
            mask: var(--_m);
    -webkit-mask-composite: source-out;
            mask-composite: subtract;
    animation: l3 1s infinite linear;
  }
  @keyframes l3 {to{transform: rotate(1turn)}}


// position: absolute;
// top: 6em;
// left: 0;
// right: 0;