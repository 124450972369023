@import 'variables';

// .detail-title {
//     font-size: 2em;
//     font-weight: bold;
//     text-align: center;
// }

.movie-detail {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    margin: 0;
    width: 90%;
    gap: 2em;
    padding-bottom: 1.5em;

    @media screen and (max-width: 768px) {
        width: 95%;
        flex-direction: column;
    }

    .detail-pic {
        width: 30em;
        border-radius: 5px;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.4);

        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }

    .detail-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        gap: 1em;
        text-align: center;
        position: relative;

        .detail-top {

            width: 100%;

            p {
                text-align: justify;
            }
        }

        .detail-title {
            font-size: 2em;
            font-weight: bold;
            text-align: center;
            padding-bottom: 1em;
        }

        .detail-infos {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1em;
            margin-top: 0.6em;
            a {
                text-decoration: none;
                color: $secondary;
                &:hover {
                    color: rgba($color: $secondary, $alpha: 0.7);
                }
            }
        }

        .original-title {
            font-size: 1.2em;
        }

        .overview {
            font-size: 1.2em;
            text-align: justify;
        }
        h3 {
            text-align: start;
        }

        .detail-bottom {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 1em;
            width: 100%;
            margin-top: 2em;
            @media screen and (max-width: 768px){
                flex-direction: column;
            }

            .back {
                width: 7em;
            }
        }

        .genres {
            font-size: 1.2em;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            gap: 0.5em;
            @media screen and (max-width: 768px) {
                justify-content: center;

            }
            li {
                display: inline;
                color: $secondary;
                list-style: none;
            }
            a {
                text-decoration: none;
                background-color: rgb(240, 240, 240);
                padding: 0.5em;
                height: 2em;
                line-height: 1em;
                border-radius: 3px;
                &:hover {
                    background-color: rgba($color: $secondary, $alpha: 0.1);
                }
            }
        }
    }
}