@import 'variables';

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding-bottom: 1.5em;

  li {
    display: inline-block;

    a {
      display: flex;
      padding: 5px;
      border: 1px solid #ccc;
      color: #333;
      text-decoration: none;

      &:hover {
        background-color: #f0f0f0;
        cursor: pointer;
      }
    }

    &.active a {
      background-color: $secondary;
      color: #fff;
    }
  }
}
