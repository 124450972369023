@import 'variables';

.error {
    color: red;
    text-align: center;
    position: absolute;
    top: 6em;
    left: 0;
    right: 0;
}

// .loading-fetching {
//     color: $primary;
//     text-align: center;
//     position: absolute;
//     top: 6em;
//     left: 0;
//     right: 0;
// }
